<template>
    <div id="Errores" :class="$route.meta.layout == 'full' ? 'w-75 mx-auto my-4': '' ">        
        <section class="body-font">            
                <div class="flex gap-6">
                    <b-row>
                        <b-col md="4" v-for="(el, k) in allErrors" :key="k">
                        <b-card class="m-0 h-100 relative">
                        <div class="flex items-center mb-3">
                            <div class="w-8 h-8 mr-1 inline-flex items-center justify-center rounded-full bg-red-700 text-white flex-shrink-0">
                            <feather-icon icon="ActivityIcon" />
                            </div>
                            <h2 class="text-gray-900 font-medium m-0">{{el.name}}</h2>
                            <router-link :to="'/editar-alerta/'+ k" v-if="permission" class="ml-1">
                                <feather-icon icon="EditIcon" />                                
                            </router-link>
                            <button v-if="permission" class="ml-1" @click="toDelete = k">
                                <feather-icon icon="Trash2Icon" class="text-red-800"/>
                            </button>
                        </div>
                        <div class="flex-grow">
                            <p class="leading-relaxed" >{{el.resume}}</p>
                            <router-link class="mt-1 text-primary inline-flex items-center" :to="$route.meta.layout == 'full' ? '/publico/alerta/'+el.name : '/alerta/'+el.name ">Instrucciones
                            <feather-icon icon="ArrowRightIcon" class="ml-1"/>
                            </router-link>
                        </div>
                        </b-card>
                    </b-col>
                    </b-row>
                    
                </div>            
        </section> 
        <confirm v-if="toDelete" :item="toDelete" @cancelar="toDelete=null" :label="'Escriba el código del error para confirmar'" @confirmation="deleteError"></confirm>    
    </div>
</template>

<script>
import {BRow, BCol, BCard} from 'bootstrap-vue'
import confirm from './Components/confirm.vue'
import firebase from 'firebase/app'
import 'firebase/database'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
export default {
    data(){
        return {
            allErrors: null,
            permission: false,
            toDelete: null
        }
    },
    created(){
        const vm = this
        firebase
        .database().ref('errors').on('value', (snapshot)=>{
            vm.allErrors = snapshot.val()
        })
        if (!this.$route.meta.currentUserId) return
        firebase.database().ref('users/'+this.$route.meta.currentUserId+'/rol').once('value', (snap)=>{
            const v = snap.val()
            if(v == 'master' || v == 'admin') this.permission = true
        })
    },
    components:{
        confirm, BCol,BRow,BCard,
        FeatherIcon
    },
    methods:{
        deleteError(event){
            if(event){
                firebase.database().ref('errors/'+this.toDelete).remove()
                this.toDelete = null
            }
        }
    }
}
</script>
<style>
.dark-layout .resume p{
    color: #d0d2d6;
}
</style>