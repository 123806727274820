<template>
    <div class="fixed top-0 right-0 bg-gray-600 bg-opacity-50 w-screen h-screen" style="z-index:9999">     
        <div class="absolute rounded bg-white p-2 right-0 left-0 m-auto top-0 bottom-0 h-80 w-3/12">
            <div class="relative my-1">
                <label for="name" class="leading-7 text-gray-600">{{label}}</label>
                <input autocomplete="off" v-model="error" type="text" id="error" name="error" :placeholder="item" class="w-full bg-white rounded border border-gray-300  outline-none text-gray-700 p-1 leading-8 transition-colors duration-200 ease-in-out">
            </div>  
            <div class="text bg-red-600 text-white rounded text-center font-semibold cursor-pointer p-1 px-3 hover:bg-red-500" @click="eliminar">Eliminar</div>
            <div class="text mt-1 bg-green-600 text-white rounded text-center font-semibold cursor-pointer p-1 px-3 hover:bg-green-500" @click="$emit('cancelar')">Cancelar</div>
        </div>        
    </div>
</template>

<script>
export default {
    data(){
        return{
            error:''
        }
    },
    methods:{
        eliminar(){
            if(this.error == this.item) this.$emit('confirmation', true)
            else this.$emit('confirmation', false)
        }
    },
    props:{
        item: String,
        label:String
    }
}
</script>